import React from 'react';

interface LocalStorageContent {
  cache: Record<string, string>;
}

const LocalStorageContext = React.createContext<LocalStorageContent>({
  cache: {},
});

const LocalStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const cacheRef = React.useRef<Record<string, string>>({});

  return (
    <LocalStorageContext.Provider value={{ cache: cacheRef.current }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const useLocalStorage = () => {
  const context = React.useContext(LocalStorageContext);
  if (context === undefined) {
    throw new Error(
      'useLocalStorage must be used within a LocalStorageProvider',
    );
  }
  return context;
};

const useLocalStorageState = (key: string, initValue?: string) => {
  const { cache } = useLocalStorage();
  const [state, setState] = React.useState<string>(() => {
    if (cache[key]) {
      return cache[key];
    }
    const loadedValue = window.localStorage.getItem(key);
    if (loadedValue !== null) {
      return loadedValue;
    }
    if (initValue !== undefined) {
      return initValue;
    }
    return 'undefined';
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, state);
    cache[key] = state;
  }, [state]);
  React.useEffect(() => {
    if (cache[key]) {
      setState(cache[key]);
    }
  }, [cache[key]]);
  return [state, setState] as const;
};

const useLocalStorageStateTyped = <V,>(key: string, initValue?: V) => {
  const [state, setState] = useLocalStorageState(
    key,
    JSON.stringify(initValue),
  );
  return [
    JSON.parse(state) as V,
    (value: V) => setState(JSON.stringify(value)),
  ] as const;
};

export {
  LocalStorageProvider,
  useLocalStorageState,
  useLocalStorageStateTyped,
};
